document.addEventListener('DOMContentLoaded', function(){

  // グローバルナビゲーションの処理
  //
  // クローン先の要素
  const globalNavMobile = document.getElementById('globalNavMobile');
  // グローバルメニューの子要素
  const navChildElm = document.getElementById('globalNav').childNodes[1];
  // グローバルメニューの子要素のクローン
  const cloneNavChildElm = navChildElm.cloneNode(true);
  // 残す要素
  const gnavLocalLink = cloneNavChildElm.querySelectorAll('.gnav-local-link');
  gnavLocalLink.forEach( elm => {
    elm.classList.remove('js-trigger');
    elm.nextElementSibling.remove();
  });
  // クローンを追加する
  globalNavMobile.insertAdjacentElement('beforeend', cloneNavChildElm);

  // toggle
  toggle('js-trigger');
  // globalNav
  globalNavigation();
  // burger
  burger('jsBurger');
  // pagetop
  pagetop('jsPagetop');
  // tab
  tab('tabNews');
  // pageAnchor
  pageAnchor();
  // localNav
  localNav();
});


// キャッシュ無効
window.onunload = function() {};


// モバイル時のメニューの可視・不可視切り替えクラス名
const openMenuClass =  'visible-mobile-menu';
const closeMenuClass = 'invisible-mobile-menu';


const swiperBanner = new Swiper('.footer-banner', {
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  loop: true,
  slidesPerView: 2,
  spaceBetween: 14,
  breakpoints: {
    768: {
      slidesPerView: 3,
      spaceBetween: 15,
    },
    992: {
      slidesPerView: 2,
      spaceBetween: 16,
    },
  },
});


/**
***  burger  */
const burger = ( burgerID ) => {
  const burger = document.getElementById( burgerID );
  let ww = window.innerWidth;
  // ない場合はなにもしない
  if( !burger ){ 
    return; 
  }
  burger.addEventListener('click', function () {
    const isActive = document.body.classList.contains(openMenuClass);

    if(isActive){
      document.body.classList.remove(openMenuClass);
      document.body.classList.add(closeMenuClass);
    }else{
      document.body.classList.add(openMenuClass);
      document.body.classList.remove(closeMenuClass);
    }
  });
  // window リサイズでメニューをクリアにする処理
  window.addEventListener( 'resize', function(){
    if( ww !== window.innerWidth){
      ww = window.innerWidth;
      // body のクラスを削除
      document.body.classList.remove(openMenuClass);
      document.body.classList.remove(closeMenuClass);
    }
  });
};


/**
***  pagetop  */
const pagetop = ( pagetopID ) => {
  const pagetop = document.getElementById( pagetopID );
  const pagetopActiveClass = 'pagetop-active';
  // ない場合はなにもしない
  if( !pagetop ){ return; }
  window.addEventListener('scroll', function(e){
    if (window.scrollY > 100) {
      pagetop.classList.add(pagetopActiveClass);
    } else {
      pagetop.classList.remove(pagetopActiveClass);
    }
  });
  pagetop.addEventListener('click', function(){
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  });
};


(function () {
  // アコーディオンの親要素のクラス名
  const accordionParentClassName = '.js-accordion-parent';
  // アコーディオンのクリックイベントを行うクラス名
  const triggerClassName = '.js-accordion-trigger';
  // アコーディオンを開くためのクラス名
  const activeClassName = 'active';

  // アコーディオンのトリガーをすべて取得する
  const acc = document.querySelectorAll(triggerClassName);

  // クリックイベント
  for ( let i = 0; i < acc.length; i++ ){
    acc[i].addEventListener('click', function(e){
      // アクティブクラスが付いているかチェックする
      const isActive = this.classList.contains(activeClassName);
      // クリックした要素のアコーディオンの親要素を取得する
      const parentAccordion = this.closest(accordionParentClassName);

      // すべてのアコーディオンを閉じる
      const allAccordions = parentAccordion.querySelectorAll(triggerClassName);
      for ( let j = 0; j < allAccordions.length; j++) {
        // Remove active class from section header
        allAccordions[j].classList.remove(activeClassName);
        // Remove the max-height class from the panel to close it
        const panel = allAccordions[j].nextElementSibling;
        const maxHeightValue = getStyle(panel, 'maxHeight');

        if (maxHeightValue !== '0px') {
          panel.style.maxHeight = null;
        };
      };

      // アクティブクラスの付け替え
      isActive ? this.classList.remove(activeClassName) : this.classList.add(activeClassName);

      // アコーディオンの開閉処理
      const panel = this.nextElementSibling;
      const maxHeightValue = getStyle( panel, 'maxHeight' );
      if (maxHeightValue !== '0px') {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px';
      };
    });
  }
}());


// toggle
const toggle = ( toggleClassName ) => {
  // トリガーのクラス名
  const triggerClassName = '.' + toggleClassName;
  // すべてのトリガーを取得する
  const allTrigger = document.querySelectorAll(triggerClassName);
  const activeClassName = 'active';
  // Window width
  let ww = window.innerWidth;

  allTrigger.forEach( function(el){
    //click
    el.addEventListener('click', function(g){
      if( ww < 992 ) {
        g.preventDefault();
        // アクティブ判定
        const isActive = this.parentNode.classList.contains(activeClassName);
        //クリックした要素の親トグル
        const parent = el.closest( '.js-trigger-origin' ).querySelectorAll( triggerClassName )[0];
        // 一度、すべてのメニューを閉じる
        document.querySelectorAll(triggerClassName).forEach( function(m){
          // 高さを取得する要素（クリックした要素の子メニュー）
          const panel = m.nextElementSibling;
          const maxHeightValue = getStyleProp(panel, 'maxHeight');
          if( m !== el && m !== parent ){
            if (maxHeightValue !== '0px') {
              m.parentNode.classList.remove(activeClassName);
              panel.style.maxHeight = null;
            };
          }
        });
        isActive ? this.parentNode.classList.remove(activeClassName) : this.parentNode.classList.add(activeClassName);
        // Toggle the panel element
        const panel = this.nextElementSibling;
        const maxHeightValue = getStyleProp( panel, 'maxHeight' );
        if (maxHeightValue !== '0px') {
          panel.style.maxHeight = null;
        } 
        else if( parent ){
          const parentPanel = parent.nextElementSibling;
          parentPanel.style.maxHeight = parentPanel.scrollHeight + panel.scrollHeight + 'px';
          panel.style.maxHeight = panel.scrollHeight + 'px';
        }
        else {
          panel.style.maxHeight = panel.scrollHeight + 'px';
        };
      };
    });
  });
  // window リサイズでメニューをクリアにする処理
  window.addEventListener( 'resize', function(){
    if( ww !== window.innerWidth){
      ww = window.innerWidth;
      allTrigger.forEach( function(el){
        // 子メニューを閉じる
        el.parentNode.classList.remove(activeClassName);
        const panel = el.nextElementSibling;
        panel.style.maxHeight = null;
      });
    }
  });
};


/**
***  ページ内リンク  */
const pageAnchor = function(){
  const elm = document.querySelectorAll('.js-page-anchor');
  // ない場合はなにもしない
  if( ! elm.length ){ return; };
  // イベント
  for ( let i = 0; i < elm.length; i++) {
    elm[i].addEventListener( 'click', function(e){
      e.preventDefault();
      const href = this.getAttribute('href');
      // ページ内リンクでない場合は何もしない
      if( href.slice(0, 1) != '#' ){
        return;
      };
      // リンク先の要素を取得
      const pageID = document.getElementById(href.slice(1));
      if( !pageID ){ return; };
      const rect = pageID.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const position = rect.top + scrollTop;
      // スクロール処理
      window.scrollTo({
        top: position - 16,
        left: 0,
        behavior: 'smooth'
      });
    });
  }
};


/**
***  tab  */
const tab = (elementID) => {
  // タブ処理を行う要素の取得
  const element = document.getElementById(elementID);
  // ない場合はなにもしない
  if( !element ){ return; }

  const tabs = element.getElementsByClassName('js-tab');
  const allContents = element.getElementsByClassName('js-contents');
  const currentClassName = 'tab-current';
  const currentViewClassName = 'tab-current-view';
  // イベント
  for (let i = 0; i < tabs.length; i++){
    tabs[i].addEventListener('click', function(){
      // データ属性を取得する
      const view = this.dataset.view;
      // .current が付与しているかチェック
      const isCurrent = this.classList.contains(currentClassName);

      if (!isCurrent) {
        // remove .current
        for ( let j = 0; j < tabs.length; j++ ){
          tabs[j].classList.remove(currentClassName);
        };
        this.classList.add(currentClassName);
      };
      // remove .current-view
      for ( let k = 0; k < allContents.length; k++ ){
        allContents[k].classList.remove(currentViewClassName);
      };
      //
      document.getElementById(view).classList.add(currentViewClassName);
    });
  };
};


/**
***  指定したプロパティを要素から取得する  */
const getStyle = (el, styleProp) => {
  let defaultView = ( el.ownerDocument || document ).defaultView;
  styleProp = styleProp.replace(/([A-Z])/g, '-$1').toLowerCase();
  return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
};


// localNav
const localNav = () => {
  const originClassName = '.js-localnav-origin';
  const triggerClassName = '.js-localnav-trigger';
  const allTrigger = document.querySelectorAll(triggerClassName);
  const activeClassName = 'active';
  let ww = window.innerWidth;
  allTrigger.forEach( function(el){
    //click
    el.addEventListener('click', function(g){
      g.preventDefault();
      // アクティブ判定
      const isActive = this.closest( originClassName ).classList.contains(activeClassName);
      // 一度、すべてのメニューを閉じる
      document.querySelectorAll(triggerClassName).forEach( function(m){
        // 高さを取得する要素（クリックした要素の子メニュー）
        const panel = m.parentNode.nextElementSibling;
        const maxHeightValue = getStyleProp(panel, 'maxHeight');
        if( m !== el ){
          if (maxHeightValue !== '0px') {
            m.closest( originClassName ).classList.remove(activeClassName);
            panel.style.maxHeight = null;
          };
        }
      });
      isActive ? this.closest( originClassName ).classList.remove(activeClassName) : this.closest( originClassName ).classList.add(activeClassName);
      const panel = this.parentNode.nextElementSibling;
      const maxHeightValue = getStyleProp( panel, 'maxHeight' );
      if (maxHeightValue !== '0px') {
        panel.style.maxHeight = null;
      } 
      else {
        panel.style.maxHeight = panel.scrollHeight + 'px';
      };
    });
  });
  // window リサイズでメニューをクリアにする処理
  window.addEventListener( 'resize', function(){
    if( ww !== window.innerWidth){
      ww = window.innerWidth;
      allTrigger.forEach( function(el){
        // 子メニューを閉じる
        el.closest( originClassName ).classList.remove(activeClassName);
        const panel = el.parentNode.nextElementSibling;
        panel.style.maxHeight = null;
      });
    }
  });
};


// globalNavigation
const globalNavigation = () => {
  // すべてのトリガーを取得する
  const allTrigger = document.querySelectorAll('.js-hover');
  const hoverClassName = 'hover';
  // Window width
  let ww = window.innerWidth;
  allTrigger.forEach( function(el){
    //mouseover
    el.parentNode.addEventListener( 'mouseenter', () => {
      if( ww >= 992 ) {
        el.parentNode.classList.add( hoverClassName );
        el.nextElementSibling.style.maxHeight = el.nextElementSibling.scrollHeight + 'px';
      }
    });
    //mouseleave
    el.parentNode.addEventListener('mouseleave', () => {
      if( ww >= 992 ) {
        el.parentNode.classList.remove( hoverClassName );
        el.nextElementSibling.style.maxHeight = null;
      }
    });
  });
  // window リサイズでメニューをクリアにする処理
  window.addEventListener( 'resize', function(){
    if( ww !== window.innerWidth){
      ww = window.innerWidth;
    }
  });
};


// 要素の指定したプロパティを取得
const getStyleProp = (el, styleProp) => {
  let defaultView = ( el.ownerDocument || document ).defaultView;
  styleProp = styleProp.replace(/([A-Z])/g, '-$1').toLowerCase();
  return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
};


// slideUp
const slideUp = (el, duration = 400) => {
  el.style.height = el.offsetHeight + 'px';
  el.offsetHeight;
  el.style.transitionProperty = 'height, margin, padding';
  el.style.transitionDuration = duration + 'ms';
  el.style.transitionTimingFunction = 'ease';
  el.style.overflow = 'hidden';
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  setTimeout(() => {
    el.style.display = 'none';
    el.style.removeProperty('height');
    el.style.removeProperty('padding-top');
    el.style.removeProperty('padding-bottom');
    el.style.removeProperty('margin-top');
    el.style.removeProperty('margin-bottom');
    el.style.removeProperty('overflow');
    el.style.removeProperty('transition-duration');
    el.style.removeProperty('transition-property');
    el.style.removeProperty('transition-timing-function');
  }, duration);
};


// slideDown
const slideDown = (el, duration = 400) => {
  el.style.removeProperty('display');
  let display = window.getComputedStyle(el).display;
  if (display === 'none') {
    display = 'block';
  }
  el.style.display = display;
  let height = el.offsetHeight;
  el.style.overflow = 'hidden';
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  el.offsetHeight;
  el.style.transitionProperty = 'height, margin, padding';
  el.style.transitionDuration = duration + 'ms';
  el.style.transitionTimingFunction = 'ease';
  el.style.height = height + 'px';
  el.style.removeProperty('padding-top');
  el.style.removeProperty('padding-bottom');
  el.style.removeProperty('margin-top');
  el.style.removeProperty('margin-bottom');
  setTimeout(() => {
    el.style.removeProperty('height');
    el.style.removeProperty('overflow');
    el.style.removeProperty('transition-duration');
    el.style.removeProperty('transition-property');
    el.style.removeProperty('transition-timing-function');
  }, duration);
};